/* eslint-disable no-unused-vars */
import "preact/devtools";
import regeneratorRuntime from "regenerator-runtime";
import intersectionObserver from "intersection-observer";
import "whatwg-fetch";
import slider from "./modules/slider";
import navigation from "./modules/navigation";
import gallery from "./modules/gallery";
import banner from "./modules/banner";
import members from "./modules/members";
import form from "./modules/form";
import filter from "./modules/filter";
import searchresults from "./modules/search-results";
import search from "./modules/Search";
import common from "./modules/common";

const chunks = {
  members,
  slider,
  navigation,
  gallery,
  banner,
  form,
  searchresults,
  filter,
  common
};

Object.keys(chunks).map(chunk => {
  try {
    chunks[chunk]();
  } catch (error) {
    if (typeof chunks[chunk] !== `function`) {
      console.error(`${chunk} JS Module is not a function!`); // eslint-disable-line no-console
    } else {
      console.error(`Error in ${chunk}: ${error}`); // eslint-disable-line no-console
    }
  }
  return 1;
});
